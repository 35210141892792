import carousel1 from "./images/carousel1.gif";
import carousel2 from "./images/carousel2.gif";
import carousel3 from "./images/caroulsel3.gif";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import "./Header.css";


function Carrousel(props) {
  const images = [carousel1, carousel2, carousel3];

  return (
    <div className="d-flex justify-content-center">
    <div className=" Carrousel">
      
      <Carousel fade slide={false}  >
     
        {images.map((src) => (
          <Carousel.Item key={src.toString()}>
             
            <img className="d-block w-100" src={src} alt={src.toString()} />
          </Carousel.Item>
        ))}
    
      </Carousel>
      
    </div>
    </div>
  );
}

export default Carrousel;
