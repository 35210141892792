import "./Header.css";
import React, { useState} from "react";
import produtoCNC from "./images/productCNC.webp";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import FadeLeftSection from "../providers/FadeLeftSection";
import FadeRightSection from "../providers/FadeRightSection";
import { useScreen } from "../providers/ScreenSize";
init(process.env.REACT_APP_EMAIL_INIT);

function Contate2(props) {
  const { isSmallScreen} = useScreen();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
 
  const isValidEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };
  const submit = () => {
    if (name && email && message) {
      const serviceId = props.serviceId
      const templateId = props.templateId
      const userId = props.userId
      const templateParams = {
        name,
        lastName,
        number,
        email,
        message,
      };

      if(isValidEmail(email)){
        emailjs.send(serviceId, templateId, templateParams, userId)
        .then((response) => alert('Email Enviado!'))
        .then((error) => console.log(error));
      }
      else {
        alert("Email invalido");
      }

    } else {
      alert("Please fill in all fields.");
    }
  };


  return (<>
   {isSmallScreen?
     <div className="row  mt-3 cncForm">
     <div className="align-self-center">
       <img
         src={produtoCNC}
        
         width="100%"
       />
     </div>
     <div className="col">
       <h1
         className="text-center text-white"
        
       >
         Peça seu orçamento!
       </h1>
       <form
         className="needs-validation d-flex align-items-center"

         noValidate
       >
         <div className="row g-2">
           <div className="col-12">
             <div className="row">
               <div className="col">
                 <label htmlFor="firstName" className="form-label">
                   Nome
                 </label>
                 <input
                   type="text"
                   className="form-control"
                   id="firstName"
                   placeholder=""
                   value=""
                   required
                   onChange={(e) => setName(e.target.value)}
                 />
                 <div className="invalid-feedback">
                   Valid first name is required.
                 </div>
               </div>
               <div className="col">
                 <label htmlFor="telefone" className="form-label">
                   Telefone
                 </label>
                 <input
                   type="tel"
                   className="form-control"
                   id="telefone"
                   placeholder=""
                   value=""
                   required
                   onChange={(e) => setNumber(e.target.value)}
                 />
                 <div className="invalid-feedback">
                   Number is required
                 </div>
               </div>
             </div>
           </div>

           <div className="col-12">
             <label htmlFor="email" className="form-label">
               Email
             </label>
             <input
               type="email"
               className="form-control"
               id="email"
               placeholder="you@example.com"
               required
               onChange={(e) => setEmail(e.target.value)}
             />
             <div className="invalid-feedback">
               Please enter a valid email address htmlFor shipping updates.
             </div>
           </div>
           <div className="col-12">
             <label className="form-label" htmlFor="productType">
               Produto
             </label>
             <fieldset id="productType">
               <label className="p-2 form-label">
                 <input
                   type="radio"
                   value="Geladeira Take"
                   name="productType"
                   checked
                   onChange={(e) => setMessage(e.target.value)}
                 />{" "}
                 Geladeira Take
               </label>
               <label className="p-2 form-label">
                 <input
                   type="radio"
                   value="Filtro Capacitivo"
                   name="productType"
                   onChange={(e) => setMessage(e.target.value)}
                 />{" "}
                 Filtro Capacitivo
               </label>
             </fieldset>
           </div>
           <div className="col-12 mb-3">
             <label htmlFor="textarea" className="form-label">
               Insira uma mensagem
             </label>
             <textarea className="form-control" id="textarea">
               
             </textarea>
           </div>
           <div className="d-flex justify-content-center">
             <button id="formBtn" className=" btn btn-primary mb-3" type="submit" onClick={submit}>
               Enviar
             </button>
           </div>
         </div>
       </form>
     </div>
   </div>
   :  <div className="d-flex cncForm ">
 
      <div className="col-6 ">
      <FadeLeftSection>
        <img
          src={produtoCNC}
          className="d-flex align-items-center"
          width="100%"
        />
        </FadeLeftSection>
      </div>
      <div className="col-6 d-flex justify-content-center align-items-center ">
        <FadeRightSection>
        <div>
        <h1
          className="text-center text-white mb-5"
   
        >
          Peça seu orçamento
        </h1>
        <form
          className="needs-validation d-flex align-items-center"  
          noValidate
        >
          <div className="row g-2">
            <div className="col-12 ">
              <div className="row noP">
                <div className="col">
                  <label htmlFor="firstName" className="form-label">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="firstName"
                    placeholder=""
                    value=""
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div className="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
                <div className="col">
                  <label htmlFor="telefone" className="form-label">
                    Telefone
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="telefone"
                    placeholder=""
                    value=""
                    required
                    onChange={(e) => setNumber(e.target.value)}
                  />
                  <div className="invalid-feedback">
                    Number is required
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="you@example.com"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="invalid-feedback">
                Please enter a valid email address htmlFor shipping updates.
              </div>
            </div>
            <div className="col-12">
              <label className="form-label" htmlFor="productType">
                Produto:
              </label>
              <fieldset id="productType">
                <label className="p-2 form-label">
                  <input
                    type="radio"
                    value="Geladeira Take"
                    name="productType"
                    checked
                    onChange={(e) => setMessage(e.target.value)}
                  />{" "}
                  Geladeira Take
                </label>
                <label className="p-2 form-label">
                  <input
                    type="radio"
                    value="Filtro Capacitivo"
                    name="productType"
                    onChange={(e) => setMessage(e.target.value)}
                  />{" "}
                  Filtro Capacitivo
                </label>
              </fieldset>
            </div>
            <div className="col-12 mb-2">
              <label htmlFor="textarea" className="form-label">
                Insira uma mensagem
              </label>
              <textarea className="form-control" id="textarea">
                
              </textarea>
            </div>
            <div className="d-flex justify-content-center">
              <button id="formBtn" className=" btn btn-primary" type="submit" onClick={submit}>
                Enviar
              </button>
            </div>
          </div>
        </form>
        </div>
        </FadeRightSection>
      </div>
    </div>}
  </>
  
  );
}

export default Contate2;
