import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Album from "./Album";
import PhotoAlbum from "react-photo-album";
import Feed from "./Feed";
import Card from "react-bootstrap/Card";
import produtoCNC from "./images/productCNC.webp";
import { useScreen } from "../providers/ScreenSize";
import instaIcon from "../components/images/instagram-1-svgrepo-com.svg"
const InstaFeeds = ({ token, ...props }) => {
  const [feeds, setFeedsData] = useState([]);
  const [showmore, setShowMore] = React.useState(false);
  const { isSmallScreen} = useScreen();


  //use useRef to store the latest value of the prop without firing the effect
  const tokenProp = useRef(token);
  tokenProp.current = token;

  const rows = [...Array(Math.ceil(feeds.length / 3))];
  const productRows = rows.map((row, idx) => feeds.slice(idx * 3, idx * 3 + 3));

  useEffect(() => {
    // this is to avoid memory leaks
    const abortController = new AbortController();

    async function fetchInstagramPost() {
      try {
        axios
          .get(
            `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${props.limit}&access_token=${props.tokenInst}`
          )
          .then((resp) => {
            let feeds2 = resp.data.data;
         
            setFeedsData(
              feeds2.map((item) => {
                return {
                  image: item.media_url,
                  text: item.caption,
                  title: item.caption,
                  media_type: item.media_type,
                };
              })
            );
          });
      } catch (err) {
        console.log("error", err);
      }
    }

    // manually call the fecth function
    fetchInstagramPost();

    return () => {
      // cancel pending fetch request on component unmount
      abortController.abort();
    };
  }, [props.limit]);


  return (
    <>
    <div className= "d-flex  justify-content-center">
      <img src={instaIcon} className="instaIcon"></img>
      <a className="insta-h1 mt-5" href='https://www.instagram.com/jfhdelivery/'  target="_blank">
      Acompanhe a gente no insta!
      </a>
    </div>
    <div className="container-fluid " id="instaFeed">
   
     
      {isSmallScreen
        ?(productRows.map((row, idx) => {
          if (idx < 1 && !showmore) {
            return (
              <div className=" justify-content-center" key={idx} >
                {row.map((src) => {
              
                    if (src.media_type!='VIDEO') {
                      return(
                 <div className="row justify-content-center mt-3"   >
                    <Card
                      className="rounded-4 instaCard"
                    >
                      <Feed
                      className="rounded-4 instaCard"
                        src={src.image}
                        widthP="100%"
                        media_type={src.media_type}
                      ></Feed>
                    </Card>
                  </div>)}
          })}
              </div>
            );
          } else if (showmore) {
            return (
              <div className=" justify-content-center" key={idx} >
              {row.map((src) => {
                    if (src.media_type!='VIDEO') {
                      return (
               <div className="row justify-content-center mt-3"   >
                  <Card
                    className="rounded-4 instaCard"
                  >
                    <Feed
                    className="rounded-4 instaCard"
                      src={src.image}
                      widthP="100%"
                      media_type={src.media_type}
                    ></Feed>
                  </Card>
                </div>)
          } else {return (<div></div>)}})}
            </div>
            );
          }
        })):(productRows.map((row, idx) => {
          if (idx <= 1 && !showmore) {
            return (
              <div className="d-flex justify-content-center" key={idx} style={{ marginBottom: "-10%" }}>
              {row.map((src) => (
                <div className="col-4">
                  <Card
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      maxHeight: "70%",
                    }}
                   
                  >
                    <Feed
                      src={src.image}
                      widthP="80%"
                      media_type={src.media_type}
                    ></Feed>
                  </Card>
                 
                </div>
              ))}
            </div>
            );
          } else if (showmore) {
            return (
              <div className="d-flex justify-content-center" key={idx} style={{ marginBottom: "-10%" }}>
                {row.map((src) => (
                  <div className="col-4">
                    <Card
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        maxHeight: "70%",
                      }}
                     
                    >
                      <Feed
                        src={src.image}
                        widthP="80%"
                        media_type={src.media_type}
                      ></Feed>
                    </Card>
                   
                  </div>
                ))}
              </div>
            );
          }
        }))}
      <button
        type="button"
        className=" btn btn-primary"
        id="verProd"
        onClick={() => setShowMore(!showmore)}
      >
        {!showmore ? "Mostrar mais" : "Mostra menos"}
      </button>
    </div>
    </>
  );
};

export default InstaFeeds;
