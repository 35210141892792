import { createContext } from "react"
import { useState,useContext } from "react"

export const HoverContext= createContext(undefined);
export const HoverProvider = ({ children }) => {
 const [hover,setHover]= useState(false)
 const [hover1,setHover1]= useState(false)
 const [hover2,setHover2]= useState(false)
 const [hover3,setHover3]= useState(false)
 return (
    <HoverContext.Provider
      value={{
        hover,
        hover1,
        hover2,
        hover3,
        changeHover: () => setHover(hover === false ? true : false),
        changeHover1: () => setHover1(hover1 === false ? true : false),
        changeHover2: () => setHover2(hover2 === false ? true : false),
        changeHover3: () => setHover3(hover3 === false ? true : false)
      }}
    >
      {children}
    </HoverContext.Provider>
  );

};

export const useHover = () => useContext(HoverContext);
