
import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import FadeLeftSection from "../providers/FadeLeftSection";

import FadeRightSection from "../providers/FadeRightSection";
import { useScreen } from "../providers/ScreenSize";
init(process.env.REACT_APP_EMAIL_INIT);
function Contate1(props) {
  const { isSmallScreen } = useScreen();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const isValidEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };
  const submit = () => {

    if (name && email && message) {
      const serviceId = props.serviceId;
      const templateId = props.templateId;
      const userId = props.userId;
      const templateParams = {
        name,
        lastName,
        number,
        email,
        message,
      };
      if (isValidEmail(email)) {
        emailjs.send(serviceId, templateId, templateParams, userId)
          .then((response) => alert('Email Enviado!'))
          .then((error) => console.log(error));
      }
      else {
        alert("Email invalido");
      }

    } else {
      alert("Please fill in all fields.");
    }
  };



  return (<div>
    {isSmallScreen ? (<div className=" d-flex justify-content-center m-5">
      <div className="contactForm " >
        <h1 id="contateLabel">Fale con</h1>
        <div className="row justify-content-center">

          <div className="col-12 ">
            <h4 className="mb-3">Matriz</h4>
            <p className="mb-0">atendimento@jfhdelivery.com.br</p>
            <p className="mb-2">vendas@jfhdelivery.com.br</p>
            <p className="mb-2">Telefone: (61) 9 9886-7848</p>
            <p className="mb-1">Redes sociais: @jfhdelivery</p>
            <p>Segunda a sexta de 9h às 18h</p>
          </div>

          <div className="col-12">
            <h4 className="mb-3">Informações</h4>
            <p className="mb-5">
              Para qualquer informação, dúvida ou comentário, por favor ligue:
              (61) 9 9881-0018 ou preencha o formulário:
            </p>
            <form className="needs-validation">
              <div className="row g-2">
                <div className="col-12">
                  <label for="firstName" className="form-label2">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div className="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
                <div className="col-12">
                  <label for="lastName" className="form-label2">
                    Sobrenome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <div className="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>
                <div className="col-12">
                  <label for="telefone" className="form-label2">
                    Telefone
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="telefone"
                    required
                    onChange={(e) => setNumber(e.target.value)}
                  />
                  <div className="invalid-feedback">
                    O numero de telefone tem que ser valido!
                  </div>
                </div>
                <div className="col-12">
                  <label for="email" className="form-label2">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="you@example.com"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="invalid-feedback">
                    Please enter a valid email address for shipping updates.
                  </div>
                </div>
                <div className="col-12 mb-5">
                  <label for="textarea" className="form-label2">
                    Insira uma mensagem
                  </label>
                  <textarea
                    className="form-control"
                    id="textarea"
                    onChange={(e) => setMessage(e.target.value)}
                  >
                    {" "}
                  </textarea>
                </div>
                <hr className="my-2" />
                <div className="d-flex justify-content-center">
                  <button id="formBtn" className=" btn btn-primary" type="submit" onClick={submit}>
                    Enviar
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>

      </div>
    </div>) : (<div className=" d-flex justify-content-center m-5">
      <div className="contactForm " id="contate">
        <h1 id="contateLabel">Fale con</h1>
        <div className=" d-flex justify-content-center m-5 ">

          <div className="order-md-last info ">
            <FadeRightSection>
              <h4 className="mb-3">Informações</h4>
              <p className="mb-0">atendimento@jfhdelivery.com.br</p>
              <p className="mb-2">vendas@jfhdelivery.com.br</p>
              <p className="mb-2">Telefone: (61) 9 9886-7848</p>
              <p className="mb-1">Redes sociais: @jfhdelivery</p>
              <p>Segunda a sexta de 9h às 18h</p>
            </FadeRightSection>
          </div>


          <div >
            <FadeLeftSection>
              <div className="Contate-nos">
                <h4 className="mb-3">Contate-nos</h4>
                <p className="mb-5">
                  Como podemos te ajudar?<br />
                  Mande sua dúvida, solicitação de orçamento ou comentário. Te responderemos em breve!
                </p>
              </div>
              <form className="needs-validation form1">
                <div className="row g-2">
                  <div className="col-12">
                    <label for="firstName" className="form-label2">
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      Valid first name is required.
                    </div>
                  </div>
                  <div className="col-12">
                    <label for="lastName" className="form-label2">
                      Sobrenome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      required
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      Valid last name is required.
                    </div>
                  </div>
                  <div className="col-12">
                    <label for="telefone" className="form-label2">
                      Telefone
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="telefone"
                      required
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      O numero de telefone tem que ser valido!
                    </div>
                  </div>
                  <div className="col-12">
                    <label for="email" className="form-label2">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="you@example.com"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      Please enter a valid email address for shipping updates.
                    </div>
                  </div>
                  <div className="col-12 mb-5">
                    <label for="textarea" className="form-label2">
                      Insira uma mensagem
                    </label>
                    <textarea
                      className="form-control"
                      id="textarea"
                      onChange={(e) => setMessage(e.target.value)}
                    >
                      {" "}
                    </textarea>
                  </div>
                  <hr className="my-2" />
                  <button
                    id="formBtn"
                    className=" btn btn-primary "
                    type="submit"
                    onClick={submit}
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </FadeLeftSection>
          </div>

        </div>

      </div>
    </div>)}
  </div>

  );
}

export default Contate1;
