import "bootstrap/dist/css/bootstrap.min.css";

function Footer() {
  return (
    <footer className=" py-3  " style={{ backgroundColor: "black" }}>
      <div className="text-center align-items-center justify-content-center">
        <p className="my-2 " style={{ color: "white", fontSize: "medium" }}>
          &copy;2023 por JFH Delivery Negócios E Representações. Desenvolvido
          por Mestari Soluções Integradas.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
