import Card from "react-bootstrap/Card";
import "./Header.css";
import Button from "react-bootstrap/Button";
import React from "react";
import Modal from "./Modal";
import { useState,useReducer,useContext,createContext } from "react";
import { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import {  ScreenSizeProvider, useScreen } from "../providers/ScreenSize";
import { HashLink } from "react-router-hash-link";

function Album(props) {
  const { isSmallScreen} = useScreen();

  const modalobjs = props.albumArray;
  const rows = [...Array(Math.ceil(props.albumArray.length / 3))];
  const productRows = rows.map((row, idx) =>
    props.albumArray.slice(idx * 3, idx * 3 + 3)
  );

  const initialState={
    show: false,
    title: null,
    text: null,
    image: null,
  };

  const reducer = (state,action)=>{
    if(action.type==='show')return {...action};
    if(action.type==='hide')return {show: !state.show};
  return new Error();
  }


  const [state, dispatch] = useReducer(reducer,initialState);


  function showModal(element) {
    dispatch({
      type:"show",
      show: true,
      title: element.title,
      text: element.text,
      image: element.image,
      id: props.albumArray.indexOf(element),
    });

  }

  function nexModal() {
    const nextId = state.id + 1;
   
    if (props.albumArray[nextId]) {
     
     showModal(props.albumArray[nextId]);
    } else {
     showModal(props.albumArray[0])
    }
  }
  function previous() {
    const previousId = state.id - 1;
    if (props.albumArray[previousId]) {
    
      showModal(props.albumArray[previousId]);
    } else {
      const lastId = props.albumArray.length - 1;
      showModal(props.albumArray[lastId]);
    }
  }
 
  return (
    <div className="text-center mt-5">
    {isSmallScreen
        ? productRows.map((row, idx) => {
            return (
              <div className=" row mt-5 " key={idx}>
                {row.map((src,index) => (
                  <Card className=" cardProd p-4" key={index}>
                    <div className="card-border">
                      <Card.Img
                        variant="top"
                        className="hover-zoom"
                        src={src.image}
                        onClick={() => showModal(src)}
                      />
                    </div>
                    <Card.Body className="card-Body">
                      {<Card.Title className="cardTitle">{src.title}</Card.Title>}
                    </Card.Body>
                    <Modal
                      show={state.show}
                      onHide={() => dispatch({ type: 'hide' })}
                      onNext={() => nexModal()}
                      onPrevious={() => previous()}
                      modalObjs={modalobjs}
                      title={state.title}
                      image={state.image}
                      text={state.text}
                      widthP="100%"
                      id={props.albumArray.indexOf(src)}
                    ></Modal>
                  </Card>
                ))}
              </div>
            );
          })
        : productRows.map((row, idx) => {
            return (
              <div className="d-flex justify-content-center" key={idx}>
                {row.map((src,index) => (
                  <Card className="cardProd" key={index}>
                    <div className="card-border">
                      <Card.Img
                        variant="top"
                        className="hover-zoom"
                        src={src.image}
                        onClick={() => showModal(src)}
                      />
                    </div>
                    <Card.Body className="card-Body">
                      {<Card.Title className="cardTitle">{src.title}</Card.Title>}
                    </Card.Body>
                    <Modal
                      show={state.show}
                      onHide={() => dispatch({ type: 'hide' })}
                      onNext={() => nexModal()}
                      onPrevious={() => previous()}
                      modalObjs={modalobjs}
                      title={state.title}
                      image={state.image}
                      text={state.text}
                      widthP="80%"
                      id={props.albumArray.indexOf(src)}
                    ></Modal>
                  </Card>
                ))}
              </div>
            );
          })}

      {props.home ? (
        <a href="#todosProd">
          <HashLink
            to="/produtos#prod"
            className="nav-link text-white"
         
          >
          
          <button
            type="button"
            className=" btn btn-primary mt-3 text-center "
            id="verProd"
            onClick={props.onProduct}
          >
            Veja todas as soluções
          </button>
          </HashLink>
          
        </a>
      ) : null}
    </div>
  );
}


export default Album;
