import sobreImage from "./images/sobre.png";
import "./Header.css";
import Fade from 'react-bootstrap/Fade'
import FadeInSection from "../providers/FadeinSection";
import { Textfit } from 'react-textfit';

function Sobre() {
  return (
    <div className=" mt-5" id="sobre">
      <div className="row justify-content-center"   >
      
        <div className="col" >
        <FadeInSection>
          <h1>Nosso prop</h1>
          <p>
          Nosso objetivo é combinar as melhores tecnologias inteligentes em um
único lugar, oferecendo consultoria e colaboração aos nossos clientes que
possam ter impacto significativo em suas rotinas diárias.
         <br/>
          Somos um espaço de exposição de tecnologias inteligentes, dedicado
a desafiar as expectativas dos clientes e transformar suas rotinas com
soluções tecnológicas inovadoras e diferenciadas, especialmente
baseadas em inteligência artificial. Oferecemos produtos finais únicos e
que promovem uma mudança de paradigma no uso da tecnologia.
          </p>
          </FadeInSection>
        </div>
      
       
        <img
          
          src={sobreImage}
          aria-hidden="true"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        />
     
      </div>
    </div>
  );
}

export default Sobre;
