import "./Header.css";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useScreen } from "../providers/ScreenSize";
import Carousel from "react-bootstrap/Carousel";
import Feed from "./Feed";
function ModalProd(props) {
  const { isSmallScreen} = useScreen();


  return (
    <>
    {isSmallScreen
      ?<Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row noP py-0 ">
          
          <div className=" order-last align-self-center mt-3  text-center">
            <h1 id="modalTitle">{props.title}</h1>
            <p id="modalText" style={{ fontSize: "Medium" }}>
              {props.text}
            </p>
          </div>
              <div className="d-flex justify-content-center">
               
  
                <div className="text-center">
                  
                  <Feed
                    src={props.image}
                    widthP={props.widthP}
                    media_type={props.media_type ? props.media_type : "IMAGE"}
                  ></Feed>
               
                </div>
  
                
              </div>
          
          </div>
     
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}  id="closeBtn" className=" btn btn-primary">Close</Button>
      </Modal.Footer>
    </Modal> :<Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row noP  ">
          <div className="col-4 order-md-last  ">
            <h1 id="modalTitle">{props.title}</h1>
            <p id="modalText" >
              {props.text}
            </p>
          </div>
          <div className="col-md-8 col-lg-8 ">
            <div className="row noP">
              <div className="d-flex">
      

                <div className="text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={props.onPrevious}
                  width="7%"
                  fill="currentColor"
                  className="bi bi-arrow-left-short align-self-center  "
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                  />
                </svg>
                  <Feed
                    src={props.image}
                    widthP={props.widthP}
                    media_type={props.media_type ? props.media_type : "IMAGE"}
                  ></Feed>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={props.onNext}
                  width="7%"
                  fill="currentColor"
                  className="bi bi-arrow-right-short  align-self-center"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  />
                </svg>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} id="closeBtn" className=" btn btn-primary">Close</Button>
      </Modal.Footer>
    </Modal> }
  </>
  );
}

export default ModalProd;
