import React from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { useScreen } from "../providers/ScreenSize";

import ReactPlayer from 'react-player'
const Feed = (props) => {
  const { isSmallScreen} = useScreen();
  let post;

  switch (props.media_type) {
    case "VIDEO":
      post = (
     
     <HoverVideoPlayer
          style={{
            width: props.widthP,
            // The container should have a set 16:9 aspect ratio
            // (https://css-tricks.com/aspect-ratio-boxes/)
          }}
          className="border rounded"
          videoSrc={props.src}
          muted={true}
          
          preload="auto"
          type="video/mp4"
          pausedOverlay={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-play"
              viewBox="0 0 16 16"
              style={{
                // Make the image expand to cover the video's dimensions
                width: "25%",
                height: "50%",
                objectFit: "cover",
                marginTop: "25%",
              }}
            >
              <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
            </svg>
          }
          loadingOverlay={<div className="loading-overlay">Loading...</div>}
          loop={true}
          restartOnPaused 
        ></HoverVideoPlayer> 
      );
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <img
          width="100%"
          className="rounded-4 "
          height="auto"
          src={props.src}
        />
      );
      break;
    default:
      post = (
        <img
          width={props.widthP}
          className="rounded-4 "
          height="auto"
          src={props.src}
        />
      );
  }

  return <React.Fragment>{post}</React.Fragment>;
};

export default Feed;
