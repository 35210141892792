import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./images/LogoAzul.png";
import "./Header.css";
import { useState } from "react";
import { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import {Link} from 'react-router-dom'
import { HashLink } from "react-router-hash-link";
import { useScreen } from "../providers/ScreenSize";
import {  useLocation } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons'
import { ChakraProvider } from '@chakra-ui/react'

import { useHover } from "../providers/ChangeOnHover";


function Header() {
  const { isSmallScreen} = useScreen();
  const [isnavVisible, setIsNavVisible] = useState(false);
  const location = useLocation().pathname;
  const hash = useLocation().hash;
  const { hover,changeHover } = useHover();
  const { hover1,changeHover1 } = useHover();
  const { hover2,changeHover2 } = useHover();
  const { hover3,changeHover3 } = useHover();
  console.log('logging hash', hash)

    const toggleNav = () => {
    setIsNavVisible(!isnavVisible);
  };
  return (
    
    <div className="header  ">

<div className="row"   >
         <div className={!isSmallScreen || isnavVisible && isSmallScreen ? "col-4":"col-8 "}> <img src={logo} alt="jfhLogo" className="logo"/></div> 
     
      
      <CSSTransition
        in={!isSmallScreen || isnavVisible && isSmallScreen}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="nav col-8">

      
          <div  className={location==="/" &&(!hash || hash==='#main') ? "nav-div-active" : ( hover? "nav-div-hover":"")}
           onMouseEnter={changeHover}
           onMouseLeave={changeHover}> 
          <HashLink
            to="/#main"
            className={location==="/" && (!hash || hash==='#main') ? "nav-link active" :( hover? "nav-link hover":"nav-link")}
            onClick={toggleNav}>
          
            Início
          </HashLink>
          </div>
          <div  className={location==="/produtos" && (hash==='#produtos')  ? "nav-div-active" :( hover1? "nav-div-hover":"") } 
           onMouseEnter={changeHover1}
           onMouseLeave={changeHover1}
           
          > 

          <HashLink
            to="/produtos#produtos"
            className={location==="/produtos"&& ( hash==='#produtos')  ? "nav-link active" : ( hover1? "nav-link hover":"nav-link")}
            onClick={toggleNav}   
           
          >
          
            Produtos
          </HashLink>
          </div>
          <div  className={location==="/" && (hash==='#sob') ? "nav-div-active" :( hover2? "nav-div-hover":"")}
            onMouseEnter={changeHover2}
            onMouseLeave={changeHover2} > 

          <HashLink
                to="/#sob"
                className={location==="/"  && (hash==='#sob')  ? "nav-link active" : ( hover2? "nav-link hover":"nav-link")}
            onClick={toggleNav}   
          >
           
            Quem Somos
          </HashLink>
</div>
<div  className={location==="/produtos"  && ( hash==='#cont')  ? "nav-div-active":( hover3? "nav-div-hover":"")}  
  onMouseEnter={changeHover3}
  onMouseLeave={changeHover3}> 

          <HashLink
           to="/produtos#cont"
           className={location==="/produtos"   && ( hash==='#cont') ? "nav-link active" : ( hover3? "nav-link hover":"nav-link")}
            onClick={toggleNav}   
          >
          
            Contato
          </HashLink>
          </div>

          {/*<ul className="nav col-2 col-lg-auto ">
              <li>
                <div className="m-2">
                  <button
                    type="button"
                    className=" btn btn-light text-dark mt-4 "
                    id="loginBtn"
                  >
                    Login
                  </button>
                </div>
              </li>
              <li>
                <div className="m-2">
                  <button
                    type="button"
                    className=" btn btn-primary text-black loginBtn  mt-4 "
                    id="signBtn"
                  >
                    Sign-up
                  </button>
                </div>
              </li>
            </ul>*/}
        </nav>
      </CSSTransition>

      <button onClick={toggleNav} className="sideMenu col-4 ">
      <HamburgerIcon />
      </button>
      </div>
     
    </div>
 
  );
}

export default Header;
