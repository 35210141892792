import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import filtroAlbum from "./components/images/filtroModal.png";
import takeAlbum from "./components/images/takeModal.png";
import Footer from "./components/Footer";
import Carrousel from "./components/Carrousel";
import Album from "./components/Album";
import Sobre from "./components/Sobre";
import InstaFeeds from "./components/InstFeeds";
import Contate1 from "./components/Contate1";
import Contate2 from "./components/Contate2";
import React from "react";
import { Routes, Route } from 'react-router-dom'
import "@fontsource/poppins";
import ReactWhatsapp from 'react-whatsapp';
import { HoverProvider,useHover } from "./providers/ChangeOnHover";
import whatsappIcon from './components/images/whatsapp.png'
import { ScreenSizeProvider, useScreen } from "./providers/ScreenSize";
import FadeLeftSection from "./providers/FadeLeftSection";

function App() {
  // const [homePage, setHomepage] = React.useState(true);
  const albumArray = [
    {
      image: filtroAlbum,
      text: `A Lumilight oferece um equipamento
  tecnológico que atua paralelamente com a rede elétrica. Sendo assim, o filtro capacitivo entrega:
  EFICIÊNCIA apresentando ganhos de 8 até 20% no consumo de energia.
  TECNOLOGIA otimizando a rede e gerando equalização seletiva dos 60HZ, inibindo artefatos harmônicos
  prejudiciais.
  PROTEÇÃO dos seus equipamentos, através da diminuição da queima de motores até 680V e da proteção de
  rede elétrica local contra distorções causadas por raios e picos de tensão, logo gera o aumento da
  vida útil dos motores eletroeletrônicos.`,
      title: `Filtro Capacitivo | Lumilight`,
    },
    {
      image: takeAlbum,
      text: `A TakeAndGo oferece geladeiras inteligentes, que ficam localizadas em condomínios, hotéis, clubes e shoppings.`,
      title: `Take and Go | Geladeiras Inteligentes`,
    },
  ];
  // const productArray = [
  //   ...albumArray,
  //   {
  //     image: hours24,
  //     text: `Cerveja Gelada a hora que voce quiser!🍻`,
  //     title: `Take and Go | Cerveja gelada sem sair de casa`,
  //   },
  // ];

const { hover } = useHover();

  return (

    <div  className="overflow">
      <div className="sticky " >
        <Header

        />
      </div>
      <div id='main'>
        <Routes> <Route path="/" element={<Carrousel />}></Route></Routes>
      </div>
      <div className=" d-flex justify-content-center" id='prod'>


        <Routes> <Route path="/produtos" element={<div > <FadeLeftSection>   <h1 id="produtosTd" className="text-center ">
        Nossas soluções tecno
        </h1></FadeLeftSection><Album albumArray={albumArray} /></div>}></Route></Routes>



        <Routes> <Route path="/" element={<div>
         <FadeLeftSection> <h1 id="produtos" className="text-center">
            SOLUÇ
          </h1> </FadeLeftSection>
          <Album
            albumArray={albumArray}
            home={true}
  
          /> </div>}></Route></Routes>

      </div>


      <div id='sob'></div>
      <Routes> <Route path="/" element={
        <Sobre></Sobre>
  }></Route></Routes>





      <Routes> <Route path="/" element={<div className=" d-flex justify-content-center m-5">
        <div > <InstaFeeds
          tokenInst={process.env.REACT_APP_INS_TOKEN_TAKE}
          limit={12}
        /></div>
      </div>}></Route></Routes>




      <div id='cont'> </div>
      <Routes> <Route path="/produtos" element={<Contate2 serviceId={process.env.REACT_APP_SEVICE_ID}
        templateId={process.env.REACT_APP_TEMPLATE_ID}
        userId={process.env.REACT_APP_USER_ID} />}></Route></Routes>



      <Routes> <Route path="/" element={
        <div ><Contate1 serviceId={process.env.REACT_APP_SEVICE_ID}
          templateId={process.env.REACT_APP_TEMPLATE_ID}
          userId={process.env.REACT_APP_USER_ID} />  </div>
      }></Route></Routes>

{/* <ReactWhatsapp number={process.env.REACT_APP_WHATSAPP} className="my-icon" message="Olá JFH Delivery, gostaria de mais informações." >
<img src={whatsappIcon} className="wIcon"></img>
  </ReactWhatsapp> */}
 
   
      <Footer />
    </div>

  );
}

function Root() {
  return (
    <HoverProvider>
    <ScreenSizeProvider>
      <App />
    </ScreenSizeProvider>
    </HoverProvider>
  );
}

export default Root;